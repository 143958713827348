import React from "react"
import styled from "@emotion/styled"

const Box = styled.div(
  {
    boxShadow: "0px 5px 15px 0px rgba(130, 136, 147, 0.15)",
    transition:
      "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3",
    background: "#fff",
  },
  props => ({
    margin: props.margin ? props.margin : "0px 50px",
    padding: props.padding ? props.padding : "70px 40px",
  })
)

export default Box
