import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"
import PrimaryTitle from "../../PrimaryTitle"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import Img from "gatsby-image"
import BoxCards from "../../boxCard"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      boda: file(name: { eq: "bodas" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventos: file(name: { eq: "eventos" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      reuniones: file(name: { eq: "reuniones" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      escolar: file(name: { eq: "escolar" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      bodaEn: file(name: { eq: "bodas-en" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventosEn: file(name: { eq: "eventos-en" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      reunionesEn: file(name: { eq: "reuniones-en" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      escolarEn: file(name: { eq: "escolar-en" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const weddings =
    props.langKey === `es`
      ? data.boda.childImageSharp.fluid
      : data.bodaEn.childImageSharp.fluid
  const events =
    props.langKey === `es`
      ? data.eventos.childImageSharp.fluid
      : data.eventosEn.childImageSharp.fluid
  const meetings =
    props.langKey === `es`
      ? data.reuniones.childImageSharp.fluid
      : data.reunionesEn.childImageSharp.fluid

  const school =
    props.langKey === `es`
      ? data.escolar.childImageSharp.fluid
      : data.escolarEn.childImageSharp.fluid

  return (
    <Layout location={props.location}>
      <PagesHero>
        <Section>
          <div className="has-text-centered mb-6">
            <PrimaryTitle textId="Events" />
            <FormattedMessage id="ServicesSubText" />
          </div>
          <div className={`mb-6`}>
            <h2 className={`is-hide`}>Bodas</h2>
            <div className="columns">
              <div className="column is-6">
                <Img alt={`weddings`} fluid={weddings} />
                <BoxCards margin={`0`} padding={`40px`}>
                  <FormattedHTMLMessage id={`ts_Weddings`} />
                </BoxCards>
              </div>
              <div className="column is-6">
                <h2 className={`is-hide`}>
                  Eventos Corporativos y Convensiones{" "}
                </h2>
                <Img alt={`events`} fluid={events} />
                <BoxCards margin={`0`} padding={`40px`}>
                  <FormattedHTMLMessage id={`ts_Events`} />
                </BoxCards>
              </div>
            </div>
          </div>

          <div className={`columns`}>
            <div className="column is-6">
              <h2 className={`is-hide`}>Reuniones</h2>
              <Img alt={`meetings`} fluid={meetings} />
              <BoxCards margin={`0`} padding={`40px`}>
                <FormattedHTMLMessage id={`ts_meetings`} />
              </BoxCards>
            </div>
            <div className="column is-6">
              <h2 className={`is-hide`}>
                Facultades, Escuelas y Universidades
              </h2>
              <Img alt={`Colleges`} fluid={school} />
              <BoxCards margin={`0`} padding={` 40px`}>
                <FormattedHTMLMessage id={`ts_Colleges`} />
                <br />
              </BoxCards>
            </div>
          </div>
        </Section>
      </PagesHero>
    </Layout>
  )
}
